import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import authSlice from './slice/auth';
import userSlice from './slice/users';
import categorySlice from './slice/category';
import productSlice from './slice/product';
import buyingSlice from './slice/buying';
import messageSlice from './slice/message';
import paymentSlice from './slice/payment';

const rootReducer = combineReducers({
  authSlice,
  userSlice,
  categorySlice,
  productSlice,
  buyingSlice,
  messageSlice,
  paymentSlice
});

export const store = configureStore({
  reducer: rootReducer
});



export * from './slice/auth';
export * from './slice/users';
export * from './slice/category';
export * from './slice/product';
export * from './slice/buying';
export * from './slice/message';
export * from './slice/payment';