import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import api from 'services';
import ADRENAAPI from 'services/apiConstant';

// Slice

const slice = createSlice({
  name: 'userSlice',
  initialState: {
    userDetails: null,
    changePasswordStatus: null,
    changePasswordFailed: null,
    sumsubAccessToken: null
  },
  reducers: {
    getUserDetailsSuccess: (state, action) => {
      state.userDetails = action.payload;
    },
    userChangePasswordSuccess: (state, action) => {
      state.changePasswordStatus = action.payload.message;
    },
    userChangePasswordFailed: (state, action) => {
      state.changePasswordFailed = action.payload.message;
    },
    updateUserProfileSuccess: (state, action) => {
      secureLocalStorage.setItem('authenticated', JSON.stringify(action.payload));
    },
    getSumsubTokenSuccess: (state, action) => {
      state.sumsubAccessToken = action.payload;
    },
    clearSumsubAccesstokenSuccess: (state, action) => {
      state.sumsubAccessToken = action.payload;
    }
  }
});
const {
  getUserDetailsSuccess,
  userChangePasswordSuccess,
  userChangePasswordFailed,
  updateUserProfileSuccess,
  getSumsubTokenSuccess,
  clearSumsubAccesstokenSuccess
} = slice.actions;
export const getUserDetails = (data) => async (dispatch) => {
  api
    .get(`${ADRENAAPI.getUserDetails}?language=${data}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getUserDetailsSuccess(result.data));
      }
    })
    .catch(() => {});
};
export const updateUserProfile = (data, language, setShowDialog) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.updateUserProfile}?language=${language}`, data)
    .then((response) => {
      let result = response.data;
      if (result.sttaus === 1) {
        dispatch(updateUserProfileSuccess(response.data));
        if (setShowDialog) {
          setShowDialog(false);
        }
      }
    })
    .catch(() => {});
};
export const userChangePassword = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.userChangePassword}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        toast.success(response.data.message);
        dispatch(userChangePasswordSuccess(response.data));
      } else {
        dispatch(userChangePasswordFailed(response.data));
      }
    })
    .catch(() => {});
};
export const userDelete = (data, navigate) => async () => {
  api
    .post(`${ADRENAAPI.userDelete}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        secureLocalStorage.clear();
        navigate('/');
        toast.success(response.data.message);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const getSumsubToken = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.getSumsubToken}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getSumsubTokenSuccess(result.data));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const clearSumsubAccesstoken = () => async (dispatch) => {
  dispatch(clearSumsubAccesstokenSuccess(null));
};
export default slice.reducer;
