import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import secureLocalStorage from 'react-secure-storage';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBF9YikFck1Vey2FsxJlkzogKi52x4DPnM',
  authDomain: 'adrena-391314.firebaseapp.com',
  projectId: 'adrena-391314',
  storageBucket: 'adrena-391314.appspot.com',
  messagingSenderId: '457960404710',
  appId: '1:457960404710:web:6b125fd7f3960ccdec2d27',
  measurementId: 'G-0WLN89RG1J'
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
// Messaging service
export let messaging = {};
if ('serviceWorker' in navigator) {
  messaging = getMessaging(app);
}
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: 'select_account '
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

export const requestForToken = async () => {
  if ('serviceWorker' in navigator) {
    // Register the service worker
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  } else {
    console.warn('Service workers are not supported in this browser.');
  }



  try {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey:
          'BNVHSp_6UHAdO0xic6S8n2vv-fPEjd54qlSxL15ty3J5W6h0rDhCVUPRBRtv8YeEhpBRrecjzty1doukYr8cDnk'
      });

      //We can send token to server
      console.log('Current token for client: ', token);
      secureLocalStorage.setItem('deviceToken', token);
    } else if (permission === 'denied') {
      //notifications are blocked
      console.log('Please allow notification persmission to have updates to your app.');
    } else {
      console.log('Please allow notification persmission to have updates to your app.');
    }
  } catch (err) {
    console.log(err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('payload', messaging);
      resolve(payload);
    });
  });
