import { Container, CssBaseline } from '@mui/material';
import React from 'react';

export default function BlankScrren() {
  return (
    <Container className="blankScreen" maxWidth="xxl">
      <CssBaseline />
    </Container>
  );
}
