import { Icon } from '@iconify/react';
import { Checkbox, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ProductBrand(props) {
  const { data, adFormData, setAdFormData } = props;
  const { t } = useTranslation();
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  useEffect(() => {
    if (!adFormData?.conservationId) {
      const index = data.findIndex((item) => item.heading === adFormData?.conservationState);
      setAdFormData((adFormData) => ({
        ...adFormData,
        conservationId: data[index]?.id
      }));
    }
  }, [adFormData]);
  const handleChange = (event) => {
    setAdFormData((adFormData) => ({
      ...adFormData,
      conservationState:
        event === 1
          ? 'New'
          : event === 2
          ? 'Great condition'
          : event === 3
          ? 'Good state'
          : event === 4
          ? 'Middle state'
          : event === 5
          ? 'Bad state'
          : '',
      conservationId: event
    }));
  };

  console.log('conservationId', adFormData?.conservationId);
  return (
    <>
      <Grid container sx={{ mt: 0, textAlign: 'left' }} spacing={1}>
        {data.map((item, index) => {
          return (
            <>
              <Grid item xs={1.6} sm={1.6} md={1} lg={1} key={index}>
                <Checkbox
                  onClick={() => handleChange(item.id)}
                  checked={adFormData?.conservationId === item.id ? true : false}
                  {...label}
                  icon={
                    <Icon icon="clarity:circle-line" color="#e7e9ea" height="25px" width="25px" />
                  }
                  checkedIcon={
                    <Icon icon="carbon:circle-filled" color="#e7e9ea" height="25px" width="25px" />
                  }
                />
              </Grid>
              <Grid item xs={10.3} sm={10.3} md={11} lg={11} sx={{ mt: 1.2 }}>
                <Typography
                  sx={{
                    fontFamily: 'setimo',
                    fontSize: '17px',
                    lineHeight: '22px'
                  }}>
                  {t(`conservationTitle.${item.heading}`)}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'setimo',
                    fontSize: '14px',
                    lineHeight: '16px'
                  }}>
                  {t(`conservationState.${item.subHeading}`)}
                </Typography>
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
}
