const ADRENAAPI = {
  userLogin: '/user/userRegisterAndLogin',
  userLogout: '/user/logout',
  getVerifyOtp: '/user/getVerificationOtp',
  updateEmailVerifyOtp: '/user/checkEmailExistAndSendOtp',
  forgotPassword: '/user/forgotPassword',
  categoryList: '/category/listAll',
  subCategoryList: '/category/getSubcategoriesByCategoryIds',
  addressSearch: '/user/googleAddressSearch',
  addressPlaced: '/user/googlePlaceDetailByID',
  getProductContactVerifyOtp: '/user/sendProductContactVerificationOtp',
  getProductContactVerifyStatus: '/user/verifyProductContactVerificationOtp',
  changePasswordWithOtp: '/user/userChangePasswordWithOtp',
  getSocialAccountInfo: '/user/socialLogin',
  addproduct: '/product/v1/addProduct',
  imageUpload: '/fileUpload/imageUpload',
  getproductDetail: 'product/detail',
  imageDelete: '/fileUpload/deleteImageFromS3',
  getUserDetails: 'user/userProfile',
  myAdsList: '/product/userProductList',
  myAdActions: '/product/myAdActions',
  userChangePassword: '/user/userChangePasswordWithOtp',
  userDelete: '/user/deleteUser',
  downloadCsv: '/product/generateProductCsvForUser',
  registerEmailFromCounterScreen: '/user/registerEmailFromCounterScreen',
  buyingCategoryList: '/category/listAllForBuyer',
  buyingSubcatListById: '/category/getSubcategoriesByCategoryIdsForBuyer',
  buyingProductList: '/product/buyerProductList',
  sellerRating: '/sellerRating/addUpdate',
  detailSellerRating: '/sellerRating/detail',
  detailAdReport: '/adReport/detail',
  adReport: '/adReport/add',
  messageList: '/chat/getPersonalUserChatList',
  chatMessages: '/chat/getChatlistOnetoOne',
  createChatRoom: '/chat/createPersonalChatRoomId',
  sendMessage: '/chat/sendChatMessage',
  deletChat: '/chat/delete',
  getSumsubToken: '/Sumsub/requestSumsubToken',
  productFavourite: '/product/addAsFavorite',
  adNotFind: '/product/addDidNotFoundEntry',
  userReportDetail: '/userReport/detail',
  userReport: '/userReport/add',
  adBrandList: '/product/brandsList',
  addSubCategory: '/category/addSubCategory',
  getfavoriteProductList: '/product/favoriteProductList',
  getNotificationList: '/user/getNotificationList',
  readNotification: '/user/readUnreadNotification',
  getPlansList: '/payment/getPlansForUser',
  addCreditCard: '/payment/addPaymentMethod',
  getCreditCardList: '/payment/getPaymentMethod',
  removeCreditCard: '/payment/removePaymentMethod',
  getServicesList: '/payment/getServicesForUsers',
  getPaymentCalculation: '/payment/v1/paymentCalculation',
  makePayment: '/payment/v1/makePayment',
  storeDownGradePayment: '/payment/downgradeStore',
  makeServicePayment: '/payment/makeServicePayment',
  checkCouponCode: '/payment/checkCouponCode',
  getSumsubStatus: '/payment/getSumsubStatus',
  updateSumsubStatus: '/payment/changeSumsubStatus',
  getUserStoreDetail: '/payment/getUserStorePlan',
  getMyStoreDetail: '/payment/myStore',
  updateStoreInfo: '/product/v1/updateStore',
  getSellerStoreProducts: '/product/sellerStoreProductList',
  storePlanPurchaseCalculation: '/payment/storePlanPurchaseCalculation',
  storePlanPurchase: '/payment/v1/storePlanPurchase', //'/payment/storePlanPurchase',
  cancelSubscription: '/payment/v1/cancelSubscription',
  checkCancelPlanValidity: '/payment/checkCancelPlanValidity',
  acceptOrRejectTheContract: '/chat/acceptOrRejectTheContract',
  signContract: '/chat/signContract',
  addAdrenaRating: '/user/addAdrenaRating',
  userAdrenaRating: '/user/userAdrenaRating',
  purchaseProduct: '/payment/purchaseProduct',
  confirmOrderBySeller: '/chat/confirmOrderBySeller',
  documentUploadForProductConfirmation: '/chat/documentUploadForProductConfirmation',
  getContractDetails: '/chat/getContractDetails',
  calculateInstallmentAmount: '/payment/calculateInstallmentAmount',
  purchaseProductInInstalment: '/payment/purchaseProductInInstalment',
  requestRefundOfProduct: '/payment/requestRefundOfProduct',
  getPaymentHistory: '/payment/paymentTransctionHistory',
  getUserOrderList: '/payment/userOrderList',
  billingHistory: '/payment/billingHistory',
  billingPdfEmail: '/payment/billingPdfEmail',
  getBillingHistory: '/payment/lastBillingDetails',
  socialAccountInfo: '/user/getSocialAccountInfo',
  // new auth flow
  authCheckEmailExist: '/user/v1/checkEmailExists',
  authUserLogin: '/user/v1/login',
  authForgotPassword: '/user/v1/forgotPassword',
  authVerifyOTP: '/user/v1/verifyOtp',
  authChangePasswordWithOtp: '/user/v1/userChangePasswordWithOtp',
  authGetVerificationOtp: '/user/v1/getVerificationOtp',
  authUserRegister: '/user/v1/register',
  updateVerifyeOtp: '/user/v1/verifyOtpByTemp',
  verifyOtp: '/user/v1/verifyOtp',
  verifyPhoneOtp: '/user/v1/verifyOtpPhone',
  getPhoneVerifyOtp: '/user/v1/getVerificationOtpWithAuth',
  updateUserProfile: '/user/v1/updateUserProfile',
  getCities: '/product/cities',
  getState: 'product/states'
};
export const STATUS_CODES = {
  SUCCESS: 200,
  FAILURE: 400,
  NOT_AUTHORIZED: 401,
  SERVER_ERROR: 500
};

export default ADRENAAPI;
