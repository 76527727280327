import Dialog from '@mui/material/Dialog';
import { DialogContent, Typography } from '@mui/material';
import AdrenaButton from 'components/button';
import { accountHeadingMobile, actionFont } from 'pages/myAccount/style';
const BrandDialog = (props) => {
  const { ShowDialog, setShowDialog, heading, subheading } = props;

  const onClose = () => {
    setShowDialog(!ShowDialog);
  };

  return (
    <Dialog
      open={ShowDialog}
      PaperProps={{
        style: {
          backgroundColor: '#323232',
          boxShadow: 'none',
          //   paddingBottom: 25,
          borderRadius: 0,
          width: '358px'
        }
      }}>
      <DialogContent sx={{ pt: 3.5 }}>
        <Typography style={accountHeadingMobile} sx={{ pb: 2 }}>
          {heading}
        </Typography>
        <Typography style={actionFont} sx={{ mt: 1, pb: 1.5 }}>
          {subheading}
        </Typography>
        <AdrenaButton
          fullWidth
          onClick={onClose}
          style={{
            backgroundColor: 'transparent',
            border: '1px solid white',
            paddingTop: '0.5rem'
          }}
          text={'OK!'}
        />
      </DialogContent>
    </Dialog>
  );
};

export default BrandDialog;
