import { Icon } from '@iconify/react';
import { Dialog, DialogTitle, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import logo from '../../assets/img/LOGO.svg';
export default function ViewInvoice(props) {
  const { open, handleClose, url } = props;
  var theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: 'black',
            boxShadow: 'none',
            borderRadius: 0
          }
        }}>
        <DialogTitle>
          <Grid container>
            <Grid item xs={11} sx={{ display: 'flex', justifyContent: 'center' }}>
              <img src={logo} alt="" width={isMobile ? '50%' : '10%'} />
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton onClick={handleClose}>
                <Icon
                  icon="ion:close-sharp"
                  color="white"
                  width={'35px'}
                  x
                  style={{ fontWeight: 700 }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <iframe className="iframe" src={url} height={'100%'} />
      </Dialog>
    </div>
  );
}
