export const boldFont = {
  fontFamily: 'setimo',
  fontSize: '1.2rem',
  lineHeight: '22px',
  fontWeight: 600,
  color: '#E7E9EA'
};

export const smallFont = {
  fontFamily: 'setimo',
  fontSize: '16px',
  lineHeight: '20.8px',
  fontWeight: 300,
  color: '#E7E9EA'
};

export const formFont = {
  fontFamily: 'setimo',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
  color: '#E7E9EA'
};
export const noDataFont = {
  fontFamily: 'setimo',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
  color: '#E01F26'
};
export const notifiyHeadingFont = {
  fontFamily: 'setimo',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 700,
  color: '#E7E9EA'
};
export const accountHeading = {
  fontFamily: 'setimoBold',
  fontSize: '32px',
  lineHeight: '36px',
  fontWeight: 700,
  color: '#E7E9EA'
};
export const accountHeadingMobile = {
  fontFamily: 'setimo',
  fontSize: '24px',
  lineHeight: '28px',
  fontWeight: 700,
  color: '#E7E9EA'
};
export const accountDivider = {
  background: '#C8C9CB',
  marginTop: '24px',
  marginBottom: '24px'
};
export const accountBox = {
  display: 'flex',
  justifyContent: 'space-between',
  mt: 2
};
export const validText = {
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Setimo',
  lineHeight: '16px',
  color: '#E7E9EA'
};
export const labelText = {
  fontSize: '12px',
  fontWeight: 700,
  fontFamily: 'Setimo',
  lineHeight: '16px',
  color: '#E7E9EA'
};

export const tabsStyle = {
  borderBottom: '1.8px solid #C8C9CB',
  minWidth: 160,
  width: 160,
  fontSize: '14px',
  fontFamily: 'setimo',
  lineHeight: '20px'
};
export const tabs = {
  color: 'white',
  borderBottom: '1.8px solid #C8C9CB',
  minWidth: '33%',
  maxWidth: '33%',
  fontSize: '14px',
  fontFamily: 'setimo',
  lineHeight: '20px'
};
export const mobileTabsStyle = {
  borderBottom: '1.8px solid #C8C9CB',
  width: 60,
  fontSize: '13px',
  fontFamily: 'setimo',
  lineHeight: '20px'
};
export const actionFont = {
  fontFamily: 'setimo',
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 400,
  color: '#E7E9EA'
};
export const contractTitle = {
  fontFamily: 'setimo',
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight: 800,
  color: 'black',
  textAlign: 'center',
  marginTop: '1rem',
  marginBottom: '1rem'
};
export const privacyPolicyFont = {
  fontFamily: 'setimo',
  fontSize: '20px',
  lineHeight: '28px',
  // fontWeight: 400,
  letterSpacing: 0.5
};

export const listHead = {
  display: 'list-item',
  fontSize: 25,
  fontWeight: 500,
  fontFamily: 'setimo',
  mt: -1.8
};
export const listText = {
  fontFamily: 'setimo',
  fontSize: 22
};
export const CounterBox = {
  backgroundColor: 'black',
  height: '136px',
  textAlign: 'center'
};
export const dotFont = {
  fontFamily: 'Setimo',
  fontSize: '72px',
  fontWeight: 700,
  lineHeight: '72px',
  letterSpacing: '0px',
  mt: 3.5,
  paddingTop: '1.8rem'
};
export const counterText = {
  fontFamily: 'Setimo',
  fontSize: '72px',
  fontWeight: 700,
  lineHeight: '72px',
  letterSpacing: '0px',
  paddingTop: '2rem'
  // text-align: left;,
};
export const socialBtnText = {
  color: '#e7e9ea',
  fontFamily: 'Setimo',
  fontSize: '15px ',
  fontWeight: '600',
  lineHeight: '17.6px',
  letterSpacing: '0.5px',
  paddingTop: '5.8px'
};

export const socialBtn = {
  borderRadius: 0,
  border: '1px solid #e7e9ea',
  height: '2.8rem',
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: '15px'
};
export const btnStyle = {
  backgroundColor: 'red',
  borderRadius: 'unset',
  mt: 0.5,
  fontFamily: 'Setimo',
  color: '#FFFFFF',
  height: 30,
  fontSize: '14px ',
  paddingLeft: '15px',
  paddingRight: '15px',
  lineHeight: '17.6px',
  letterSpacing: '0.5px',

  ':hover': {
    color: '#FFFFFF',
    backgroundColor: '#B5000D'
  },
  '&.Mui-disabled': {
    background: '#C8C9CB',
    color: '#9D9EA0'
  },
  textTransform: 'none'
};
export const messageStyle = {
  fontFamily: 'setimo',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400
  // whiteSpace: 'pre-line'
};
export const contractContentFont = {
  fontFamily: 'setimo',
  fontSize: '20px',
  lineHeight: '26px',
  fontWeight: 400,
  color: 'black'
};
