import { InputLabel, TextField, Typography } from '@mui/material';
import React from 'react';
import './index.css';
import InputAdornment from '@mui/material/InputAdornment';
import { useState } from 'react';

export default function AdrenaInput(props) {
  const [color, setColor] = useState(false);

  const {
    title,
    type,
    formError,
    value,
    onChange,
    endIcon,
    PasswordShowClick,
    label,
    labelStyle,
    placeholder,
    startIcon,
    multiline,
    style,
    labelIcon,
    minRows,
    maxLength,
    maxRows,
    onKeyUp,
    onClick,
    disabled,
    name,
    readOnly,
    onKeyDown
  } = props;

  return (
    <>
      <InputLabel
        sx={{
          fontSize: '12px',
          fontWeight: 700,
          fontFamily: 'Setimo',
          lineHeight: '16px',
          color: '#E7E9EA',
          mb: -1.6,
          mt: 1,
          display: 'flex'
        }}
        style={labelStyle}>
        {label} &nbsp;&nbsp;<span style={{ marginTop: '-2px' }}>{labelIcon}</span>
      </InputLabel>
      <TextField
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        controls={false}
        disabled={disabled}
        margin="normal"
        onBlur={() => setColor(false)}
        variant="outlined"
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        required
        fullWidth
        style={style}
        type={type}
        onClick={onClick}
        sx={{
          backgroundColor: color ? '#323232' : 'transparent',
          border: formError ? '1px solid red' : ''
        }}
        onChange={(e) => {
          setColor(true);
          onChange(e.target.value);
        }}
        value={value}
        placeholder={placeholder}
        id={type}
        label={title}
        name={name}
        autoComplete={type}
        inputProps={{
          maxLength: maxLength,
          style: { color: '#E7E9EA' },
          readOnly: readOnly
        }}
        InputProps={{
          style: {
            borderRadius: 0
          },
          startAdornment: <InputAdornment position="start">{startIcon}</InputAdornment>,
          endAdornment: (
            <InputAdornment onClick={PasswordShowClick} position="end">
              {endIcon}
            </InputAdornment>
          )
        }}
      />
      <Typography
        sx={{ fontSize: '14px', fontFamily: 'setimo', fontWeight: 400, ml: 1, color: '#E01F26' }}>
        {formError ? formError : ''}
      </Typography>
    </>
  );
}
