/* @format / */
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

// store
// import store from "../store";
//store.subscribe(listener);

var bearerToken = '';
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/json',
    key: process.env.REACT_APP_AUTH_API_KEY
  }
});

// Add a request interceptor
// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  var loginData = secureLocalStorage.getItem('authenticated');
  var Token = JSON.parse(loginData);

  if (loginData) {
    bearerToken = `Bearer ${Token?.token}`;
  }
  request.headers.Authorization = bearerToken;
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  if (error.message === 'Network Error') {
    return;
  } else if (error.response.status === 401) {
    secureLocalStorage.clear();
    window.location.href = '/auth';
    return;
  } else if (error.response.data.status === 404) {
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
api.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

api.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default api;
