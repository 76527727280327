import { Icon } from '@iconify/react';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import AdrenaButton from 'components/button';
import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function SellingFooter(props) {
  const { onClick, disabled, show, setShow, text, Progress } = props;
  const { t } = useTranslation();
  var theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#B5000D' : '#308fe8'
    }
  }));

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <BorderLinearProgress variant="determinate" value={Progress ? Progress : 0} />
        </Grid>
      </Grid>
      <Box
        sx={{
          pt: show ? 1 : 1.5,
          px: { xs: 2, sm: 2, md: 14, lg: 14 },
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <Link to={process.env.REACT_APP_WHATSAPP_URL} target="_blank">
          <Icon icon="codicon:question" color="#e7e9ea" width={25} height={25} />
        </Link>
        {show ? (
          <AdrenaButton
            disabled={disabled}
            onClick={onClick}
            text={text ? text : t('login.btnContinue')}
            style={{ minWidth: 155, paddingTop: '10px' }}
          />
        ) : isMobile ? (
          <Button onClick={() => setShow(true)}>
            <Typography
              sx={{
                textDecoration: 'underline',
                fontSize: '14px',
                fontWeight: 700,
                fontFamily: 'Setimo',
                color: '#E7E9EA',
                lineHeight: '14px',
                letterSpacing: '0.5 px',
                mt: 0.5
              }}>
              {t('selling.chooseMoreThanOne')}
            </Typography>
          </Button>
        ) : (
          ''
        )}
      </Box>
    </>
  );
}
