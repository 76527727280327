import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ShareSocial } from 'react-share-social';
import { IconButton } from '@mui/material';
import { Icon } from '@iconify/react';
import './index.css';
import { useTranslation } from 'react-i18next';
const ShareAdDialog = (props) => {
  const { open, setOpen, url } = props;
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };
  const style = {
    root: {
      background: '#323232',
      borderRadius: 3,
      border: 0,
      color: 'white'
    },
    copyContainer: {
      border: '1px solid white',
      background: 'transparent'
    },
    title: {
      color: 'aquamarine',
      fontStyle: 'setimo',
      marginBottom: '-1rem'
    },
    copyIcon: {
      color: 'red'
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#323232',
            boxShadow: 'none',
            width: 'auto',
            borderRadius: 0
          }
        }}>
        <DialogTitle>
          <IconButton sx={{ float: 'right', marginRight: '-12px' }} onClick={handleClose}>
            <Icon
              icon="ion:close-sharp"
              color="white"
              width={'23.63px'}
              height={'23.63px'}
              style={{ fontWeight: 700 }}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mt: -3 }}>
          <ShareSocial
            title={t('shareAd.title')}
            style={style}
            url={url}
            socialTypes={['facebook', 'twitter', 'reddit', 'linkedin', 'whatsapp']}
            onSocialButtonClicked={(data) => console.log(data)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ShareAdDialog;
