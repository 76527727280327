import { Button, InputLabel } from '@mui/material';
import React from 'react';

const AdrenaLabel = (props) => {
  const { text, style, onClick, buttonStyle } = props;
  return (
    <InputLabel
      fullWidth
      onClick={onClick}
      sx={{
        fontSize: '14px',
        fontWeight: 700,
        fontFamily: 'Setimo',
        lineHeight: '100%',
        letterSpacing: '0.5px',
        color: '#E7E9EA',
        textAlign: 'center',
        my: 2
      }}
      style={style}>
      <Button
        // onClick={onClick}
        sx={{
          color: '#E7E9EA',
          fontSize: '14px',
          fontWeight: 700,
          fontFamily: 'Setimo'
        }}
        style={buttonStyle}>
        {text}
      </Button>
    </InputLabel>
  );
};
export default AdrenaLabel;
