import React, { useState, useEffect } from 'react';
// import toast, { Toaster } from 'react-hot-toast';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { onMessageListener, requestForToken } from '../../fireBase';
import NotificationSound from 'assets/audio/notification-sound.mp3';
import logo from '../../assets/img/notification-logo.png';
const Notifications = () => {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const audio = new Audio(NotificationSound);

  const notify = () =>
    toast(<ToastDisplay />, {
      hideProgressBar: true
    });
  requestForToken();
  // const audioPlayer = useRef(null);
  useEffect(() => {
    if (notification?.title) {
      notify();
      // audioPlayer.current.play();
      audio
        .play()
        .then(() => {
          console.log('Play Success');
        })
        .catch((error) => {
          console.log('error playing audio', error);
        });
    }
  }, [notification]);
  function ToastDisplay() {
    return (
      <div style={{ display: 'inline-flex' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} height={'50px'} width={'50px'} />
        </div>
        <div style={{ marginLeft: '1rem' }}>
          <p>
            <b>{notification?.title}</b>
          </p>
          <p style={{ marginTop: '-1rem' }}>{notification?.body}</p>
        </div>
      </div>
    );
  }

  onMessageListener()
    .then((payload) => {
      console.log('payload', payload);
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body
      });
    })
    .catch((err) => console.log('failed: ', err));

  return <>{/* <audio ref={audioPlayer} src={NotificationSound} /> */}</>;
};

export default Notifications;
