import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent, IconButton, Typography, styled } from '@mui/material';
import { Icon } from '@iconify/react';
import AdrenaInput from 'components/input';
import AdrenaButton from 'components/button';
import { useTranslation } from 'react-i18next';
import { formFont } from 'pages/myAccount/style';
import Rating from '@mui/material/Rating';
import PropTypes from 'prop-types';
const AdrenaRatingDialog = (props) => {
  const { ShowDialog, setShowDialog, onClick, setRatingData, ratingData } = props;
  const { t } = useTranslation();
  const onClose = () => {
    setShowDialog(!ShowDialog);
  };
  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: 'red'
    },
    '& .MuiRating-iconHover': {
      color: 'red'
    },
    '& .MuiRating-iconEmpty': {
      color: 'white'
    }
  });
  const customIcons = {
    1: {
      icon: <Icon icon="mdi:number-1-circle-outline" width="30" height="30" />,
      label: '1'
    },
    2: {
      icon: <Icon icon="mdi:number-2-circle-outline" width="30" height="30" />,
      label: '2'
    },
    3: {
      icon: <Icon icon="mdi:number-3-circle-outline" width="30" height="30" />,
      label: '3'
    },
    4: {
      icon: <Icon icon="mdi:number-4-circle-outline" width="30" height="30" />,
      label: '4'
    },
    5: {
      icon: <Icon icon="mdi:number-5-circle-outline" width="30" height="30" />,
      label: '5'
    },
    6: {
      icon: <Icon icon="mdi:number-6-circle-outline" width="30" height="30" />,
      label: '6'
    },
    7: {
      icon: <Icon icon="mdi:number-7-circle-outline" width="30" height="30" />,
      label: '7'
    },
    8: {
      icon: <Icon icon="mdi:number-8-circle-outline" width="30" height="30" />,
      label: '8'
    },
    9: {
      icon: <Icon icon="mdi:number-9-circle-outline" width="30" height="30" />,
      label: '9'
    },
    10: {
      icon: <Icon icon="mdi:numeric-10-circle-outline" width="30" height="30" />,
      label: '10'
    }
  };
  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  IconContainer.propTypes = {
    value: PropTypes.number.isRequired
  };
  return (
    <Dialog
      open={ShowDialog}
      PaperProps={{
        style: {
          backgroundColor: '#323232',
          boxShadow: 'none',
          width: '445px',
          borderRadius: 0
        }
      }}>
      <DialogTitle>
        <IconButton sx={{ float: 'right', marginRight: '-12px' }} onClick={onClose}>
          <Icon
            icon="ion:close-sharp"
            color="white"
            width={'23.63px'}
            height={'23.63px'}
            style={{ fontWeight: 700 }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            color: '#E7E9EA',
            fontSize: '22px',
            fontFamily: 'setimo',
            fontWeight: 700
          }}>
          {t('adrenaRating.heading')}
        </Typography>
        <Typography style={formFont} sx={{ py: 3 }}>
          On a scale of 0 to 10, how likely are you to recommend our business to a friend or
          colleague?
        </Typography>
        <StyledRating
          max={10}
          name="customized-color"
          value={ratingData?.rate}
          IconContainerComponent={IconContainer}
          onChange={(event, newValue) => {
            setRatingData((ratingData) => ({
              ...ratingData,
              rate: newValue
            }));
          }}
        />
        <AdrenaInput
          multiline={true}
          maxRows={4}
          minRows={4}
          placeholder={t('selling.typeHere')}
          label={t('adrenaRating.Comment')}
          value={ratingData?.review}
          labelStyle={{ marginTop: '1rem' }}
          onChange={(val) => {
            setRatingData((ratingData) => ({
              ...ratingData,
              review: val
            }));
          }}
        />
        <AdrenaButton fullWidth text="OK!" onClick={onClick} />
      </DialogContent>
    </Dialog>
  );
};

export default AdrenaRatingDialog;
