import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from 'services';
import ADRENAAPI from 'services/apiConstant';

// Slice
const slice = createSlice({
  name: 'messageSlice',
  initialState: {
    messageListData: null,
    messageData: null,
    chatRoomId: null,
    chatListData: [],
    readMessageStatus: false,
    deletChatStatus: false,
    notificationListData: null
  },
  reducers: {
    messageListSuccess: (state, action) => {
      state.messageListData = action.payload;
    },
    chatMessagesSuccess: (state, action) => {
      state.messageData = action.payload;
      let chatList = action.payload.data;
      state.chatListData = chatList;
      state.deletChatStatus = false;
    },
    addChatMessageSuccess: (state, action) => {
      let newMessage = action.payload;
      const dateIndex = state.chatListData.findIndex((item) => item.date === newMessage?.date);
      console.log('dateidnxe', dateIndex);
      if (dateIndex < 0) {
        let obj = {
          date: newMessage?.date,
          data: [newMessage?.data]
        };
        let temp = state.chatListData;
        temp.splice(0, 0, obj);
        state.chatListData = temp;
      } else {
        let objIndex = state.chatListData[dateIndex]['data'].findIndex(
          (item) => item == newMessage?.data
        );
        console.log('dateidnxe', objIndex);
        if (objIndex < 0);
        {
          let temp = state.chatListData;
          temp[dateIndex]['data'].push(newMessage?.data);
          state.chatListData = temp;
        }
      }
    },
    createChatRoomSuccess: (state, action) => {
      state.chatRoomId = action.payload;
    },
    updateChatMessageListSuccess: (state, action) => {
      state.readMessageStatus = true;
      let index = state.messageListData['data']?.findIndex(
        (item) => item.chatRoomId === action.payload.finalData.chatRoomId
      );
      state.messageListData['data'][index] = action.payload.finalData;
      state.messageListData.buyerTopChatCount = action.payload.buyerTopChatCount;
      state.messageListData.sellerTopChatCount = action.payload.sellerTopChatCount;
    },
    deletChatSuccess: (state, action) => {
      state.messageListData['data'] = state.messageListData['data'].filter(
        (item) => item.chatRoomId !== action.payload
      );
      state.deletChatStatus = true;
    },
    clearChatRoomIdSuccess: (state, action) => {
      state.chatRoomId = action.payload;
    },
    getNotificationListSuccess: (state, action) => {
      state.notificationListData = action.payload;
    },
    readNotificationSuccess: (state, action) => {
      if (action.payload.type) {
        state.notificationListData = action.payload.data.data;
      } else {
        let index = state.notificationListData.data.findIndex(
          (item) => item._id === action.payload.data.data.data._id
        );
        state.notificationListData.data[index] = action.payload.data.data.data;
        state.notificationListData.unreadCount = action.payload.data.data.unreadCount;
      }
    },
    clearMessageListDataSuccess: (state, action) => {
      state.messageListData = action.payload;
    },
    clearMessageDataSuccess: (state) => {
      state.messageData = null;
    }
  }
});
const {
  messageListSuccess,
  chatMessagesSuccess,
  createChatRoomSuccess,
  addChatMessageSuccess,
  updateChatMessageListSuccess,
  deletChatSuccess,
  clearChatRoomIdSuccess,
  getNotificationListSuccess,
  readNotificationSuccess,
  clearMessageListDataSuccess,
  clearMessageDataSuccess
} = slice.actions;

export const messageList = (data, setLoader, fetchChatData) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.messageList}`, data)
    .then((response) => {
      if (response.data.status) {
        dispatch(messageListSuccess(response.data));
        if (fetchChatData) {
          fetchChatData();
        }
        if (setLoader) {
          setLoader(false);
        }
      } else {
        dispatch(messageListSuccess(null));
        if (setLoader) {
          setLoader(false);
        }
      }
    })
    .catch(() => {});
};

export const addChatMessageToList = (data) => async (dispatch) => {
  dispatch(addChatMessageSuccess(data));
};
export const updateChatMessageList = (data) => async (dispatch) => {
  dispatch(updateChatMessageListSuccess(data));
};
export const clearMessageListData = () => async (dispatch) => {
  dispatch(clearMessageListDataSuccess(null));
};
export const clearMessageData = () => async (dispatch) => {
  dispatch(clearMessageDataSuccess(null));
};

export const chatMessages = (data, setLoader) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.chatMessages}`, data)
    .then((response) => {
      if (response.data.status) {
        dispatch(chatMessagesSuccess(response.data));
        if (setLoader) {
          setLoader(false);
        }
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};
export const createChatRoom = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.createChatRoom}`, data)
    .then((response) => {
      if (response.data.status) {
        dispatch(createChatRoomSuccess(response.data?.date));
        // setLoader(false);
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};
export const clearChatRoomId = () => async (dispatch) => {
  dispatch(clearChatRoomIdSuccess(null));
};

export const sendMessage = (data, setLoader, setMessages, setShowDialog) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.sendMessage}`, data)
    .then((response) => {
      if (response.data.status) {
        dispatch(createChatRoomSuccess(null));
        setLoader(false);
        setMessages('');
        setShowDialog(false);
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch((e) => {
      console.log(e.error);
    });
};
export const deletChat =
  (data, language, setShowDialog, setAnchorEl, setShow, navigate) => async (dispatch) => {
    api
      .delete(`${ADRENAAPI.deletChat}/${data}/?language=${language}`, data)
      .then((response) => {
        if (response.data.status) {
          dispatch(deletChatSuccess(response?.data?.data));
          toast.success(response?.data?.message);
          setShowDialog(false);
          setAnchorEl(null);
          if (setShow) {
            setShow(true);
          }
          navigate('/messages');
        } else {
          toast.warning(response?.data?.message);
        }
      })
      .catch(() => {});
  };
export const getNotificationList = (data, setLoader) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.getNotificationList}`, data)
    .then((response) => {
      if (response.data.status) {
        dispatch(getNotificationListSuccess(response.data));
        setLoader(false);
      } else {
        // toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const readNotification = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.readNotification}`, data)
    .then((response) => {
      if (response.data.status) {
        dispatch(readNotificationSuccess({ data: response.data, type: data?.isReadAll }));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export default slice.reducer;
