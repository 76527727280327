import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from 'services';
import ADRENAAPI from 'services/apiConstant';

// Slice

const slice = createSlice({
  name: 'productSlice',
  initialState: {
    myAdsListData: null,
    sellerAdTotalCount: null,
    favouriteProductList: null,
    adrenaRatingData: null,
    contractInfo: null,
    paymentInstallmentData: [],
    orderListTotal: null,
    orderListData: null,
    cityListData: [],
    stateListData: []
  },
  reducers: {
    myAdsListSuccess: (state, action) => {
      state.myAdsListData = action.payload.data;
      state.sellerAdTotalCount = action.payload.totalCount;
    },
    myAdDeleteSuccess: (state, action) => {
      let deleteId = action.payload.id;
      state.myAdsListData = state.myAdsListData.filter((item) => item.id !== deleteId);
    },
    getfavoriteProductListSuccess: (state, action) => {
      state.favouriteProductList = action.payload;
    },
    removeFavouriteAdsSuccess: (state, action) => {
      let deleteId = action.payload.id;
      state.favouriteProductList = state.favouriteProductList.filter(
        (item) => item.id !== deleteId
      );
    },
    userAdrenaRatingSuccess: (state, action) => {
      state.adrenaRatingData = action.payload;
    },
    getContractDetailsSuccess: (state, action) => {
      state.contractInfo = action.payload;
    },
    calculateInstallmentAmountSuccess: (state, action) => {
      state.paymentInstallmentData = action.payload;
    },
    getUserOrderListSuccess: (state, action) => {
      state.orderListData = action.payload.data;
      state.orderListTotal = action.payload.totalItems;
    },
    getCitiesSuccess: (state, action) => {
      state.cityListData = action.payload;
    },

    clearGetCitiesSuccess: (state) => {
      state.cityListData = [];
    },
    getStateSuccess: (state, action) => {
      state.stateListData = action.payload;
    },
    cleargetStateSuccess: (state) => {
      state.stateListData = [];
    }
  }
});
const {
  myAdsListSuccess,
  myAdDeleteSuccess,
  getfavoriteProductListSuccess,
  removeFavouriteAdsSuccess,
  userAdrenaRatingSuccess,
  getContractDetailsSuccess,
  calculateInstallmentAmountSuccess,
  getUserOrderListSuccess,
  getCitiesSuccess,
  clearGetCitiesSuccess,
  getStateSuccess,
  cleargetStateSuccess
} = slice.actions;

export const myAdsList = (data, setLoader) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.myAdsList}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*',
        key: process.env.REACT_APP_AUTH_API_KEY
      }
    })
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(myAdsListSuccess(result));
        setLoader(false);
      } else {
        toast.warning(response.data.message);
        setLoader(false);
      }
    })
    .catch(() => {});
};
export const myAdActions = (data, adAction) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.myAdActions}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(response.data.message);
        if (adAction) {
          dispatch(myAdDeleteSuccess(result.data));
        }
      }
    })
    .catch(() => {});
};
export const getfavoriteProductList = (data, setLoader) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.getfavoriteProductList}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getfavoriteProductListSuccess(result.data));
        setLoader(false);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const removeFavouriteAds = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.productFavourite}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(removeFavouriteAdsSuccess(result.data));
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};
export const addAdrenaRating = (data, setRatingDialog) => async () => {
  api
    .post(`${ADRENAAPI.addAdrenaRating}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        toast.success(response?.data?.message);
        setRatingDialog(false);
      } else {
        toast.warning(response?.data?.message);
        setRatingDialog(false);
      }
    })
    .catch(() => {});
};
export const userAdrenaRating = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.userAdrenaRating}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(userAdrenaRatingSuccess(result.date));
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};
export const confirmOrderBySeller = (data, setConfirmStatus) => async () => {
  api
    .post(`${ADRENAAPI.confirmOrderBySeller}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        setConfirmStatus(true);
        toast.success(response?.data?.message);
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};
export const getContractDetails = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.getContractDetails}`, data)
    .then((response) => {
      let result = response.data;
      if (result.success) {
        dispatch(getContractDetailsSuccess(result?.data));
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};
export const calculateInstallmentAmount = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.calculateInstallmentAmount}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(calculateInstallmentAmountSuccess(result?.data));
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};
export const getUserOrderList = (data, setIsLoading) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.getUserOrderList}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getUserOrderListSuccess(result));
        setIsLoading(false);
      } else {
        toast.warning(response?.data?.message);
        setIsLoading(false);
      }
    })
    .catch(() => {});
};

// get Cities
export const getCities = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.getCities}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getCitiesSuccess(result?.data));
      } else {
        // toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};
// clearGetCities
export const clearGetCities = () => async (dispatch) => {
  dispatch(clearGetCitiesSuccess());
};

// get Cities
export const getState = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.getState}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getStateSuccess(result?.data));
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};
// clearGetCities
export const cleargetState = () => async (dispatch) => {
  dispatch(cleargetStateSuccess());
};
export default slice.reducer;
