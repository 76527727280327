import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from 'services';
// import api from 'services';
import ADRENAAPI from 'services/apiConstant';

// Slice
const slice = createSlice({
  name: 'buyingSlice',
  initialState: {
    buyingCategoryData: null,
    buyingSubCatData: null,
    buyingProductsData: null,
    sellerRatingDeatil: null,
    adReportDetail: null,
    adReprtStatus: false,
    userReportData: null,
    userReportStatus: false,
    adBrandListData: null,
    featuredProductsData: null,
    searchProductData: []
  },
  reducers: {
    buyingCategoryListSuccess: (state, action) => {
      state.buyingCategoryData = action.payload.data;
    },
    buyingSubcatListByIdSuccess: (state, action) => {
      state.buyingSubCatData = action.payload.data;
    },
    buyingProductListSuccess: (state, action) => {
      if (action?.payload?.type) {
        state.buyingProductsData.data = [
          ...state.buyingProductsData.data,
          ...action.payload.data.data
        ];
      } else {
        state.buyingProductsData = action.payload.data;
      }
    },
    searchProductListSuccess: (state, action) => {
      state.searchProductData = action.payload.data;
    },
    clearSearchProductListSuccess: (state) => {
      state.searchProductData = [];
    },
    detailSellerRatingSuccess: (state, action) => {
      state.sellerRatingDeatil = action.payload.data;
    },
    detailAdReportSuccess: (state, action) => {
      state.adReportDetail = action.payload.data;
    },
    detailAdReportStatus: (state) => {
      state.adReprtStatus = !state.adReprtStatus;
    },
    productFavouriteSuccess: (state, action) => {
      let objId = action.payload.id;
      let objIndex = state.buyingProductsData['data'].findIndex((item) => item.id === objId);
      state.buyingProductsData['data'][objIndex] = action.payload;
    },
    userReportDetailSuccess: (state, action) => {
      state.userReportData = action.payload.data;
    },
    userReportSuccess: (state, action) => {
      state.userReportData = action.payload.data;
    },
    userReportStatus: (state) => {
      state.userReportStatus = !state.userReportStatus;
    },
    adBrandListSuccess: (state, action) => {
      state.adBrandListData = action.payload;
    },
    featuredProductListSuccess: (state, action) => {
      state.featuredProductsData = action.payload;
    }
  }
});
const {
  buyingCategoryListSuccess,
  buyingSubcatListByIdSuccess,
  buyingProductListSuccess,
  searchProductListSuccess,
  clearSearchProductListSuccess,
  detailSellerRatingSuccess,
  detailAdReportSuccess,
  detailAdReportStatus,
  productFavouriteSuccess,
  userReportDetailSuccess,
  userReportSuccess,
  userReportStatus,
  adBrandListSuccess,
  featuredProductListSuccess
} = slice.actions;

export const buyingCategoryList = (data) => async (dispatch) => {
  api
    .get(`${ADRENAAPI.buyingCategoryList}?language=${data.language}`, {
      accept: '*',
      key: process.env.REACT_APP_AUTH_API_KEY
    })
    .then((response) => {
      if (response) {
        dispatch(buyingCategoryListSuccess(response.data));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const buyingSubcatListById = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.buyingSubcatListById}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*',
        key: process.env.REACT_APP_AUTH_API_KEY
      }
    })
    .then((response) => {
      if (response) {
        dispatch(buyingSubcatListByIdSuccess(response.data));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const buyingProductList = (data, setLoader, setAnchorEl, type) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.buyingProductList}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*',
        key: process.env.REACT_APP_AUTH_API_KEY
      }
    })
    .then((response) => {
      if (response) {
        dispatch(
          buyingProductListSuccess({
            data: response.data,
            type: type
          })
        );
        setLoader(false);
        setAnchorEl(null);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const searchProductList = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.buyingProductList}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*',
        key: process.env.REACT_APP_AUTH_API_KEY
      }
    })
    .then((response) => {
      if (response) {
        dispatch(searchProductListSuccess(response.data));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const clearSearchProductList = () => async (dispatch) => {
  dispatch(clearSearchProductListSuccess());
};
export const featuredProductList = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.buyingProductList}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*',
        key: process.env.REACT_APP_AUTH_API_KEY
      }
    })
    .then((response) => {
      if (response) {
        dispatch(featuredProductListSuccess(response.data));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const adNotFind = (data, setLoader, setMessage) => async () => {
  api
    .post(`${ADRENAAPI.adNotFind}`, data, {
      headers: {
        'Content-Type': 'application/json',
        accept: '*',
        key: process.env.REACT_APP_AUTH_API_KEY
      }
    })
    .then((response) => {
      if (response.data.status) {
        toast.success(response.data.message);
        setLoader(false);
        setMessage('');
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const adBrandList = (id) => async (dispatch) => {
  api
    .get(`${ADRENAAPI.adBrandList}?categoryId=${id ? id : ''}`, {
      headers: {
        accept: '*',
        key: process.env.REACT_APP_AUTH_API_KEY
      }
    })
    .then((response) => {
      if (response.data.status) {
        dispatch(adBrandListSuccess(response.data.data));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const sellerRating = (data, setShowDialog) => async () => {
  api
    .post(`${ADRENAAPI.sellerRating}`, data)
    .then((response) => {
      if (response.data.status) {
        toast.success(response.data.message);
        setShowDialog(false);
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const detailSellerRating = (data, language) => async (dispatch) => {
  api
    .get(`${ADRENAAPI.detailSellerRating}/${data}?language=${language}`)
    .then((response) => {
      if (response.data.status) {
        dispatch(detailSellerRatingSuccess(response.data));
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const detailAdReport = (data, language) => async (dispatch) => {
  api
    .get(`${ADRENAAPI.detailAdReport}/${data}?language=${language}`)
    .then((response) => {
      if (!response.data.status) {
        toast.warning(response.data.message);
      } else {
        dispatch(detailAdReportSuccess(response.data));
      }
    })
    .catch(() => {});
};
export const adReport = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.adReport}`, data)
    .then((response) => {
      if (response.data.status) {
        toast.success('Add report successfully');
        dispatch(detailAdReportSuccess({ data: response.data.date }));
        dispatch(detailAdReportStatus());
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const updateAdReportStatus = (setShowDialog) => async (dispatch) => {
  dispatch(detailAdReportStatus());
  setShowDialog(false);
};
export const productFavourite = (data, setIsFavourite) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.productFavourite}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(productFavouriteSuccess(result.data));
        setIsFavourite(data.status);
      } else {
        toast.warning(response?.data?.message);
      }
    })
    .catch(() => {});
};
export const userReportDetail = (data, language) => async (dispatch) => {
  api
    .get(`${ADRENAAPI.userReportDetail}/${data}?language=${language}`)
    .then((response) => {
      if (!response.data.status) {
        toast.warning(response.data.message);
      } else {
        dispatch(userReportDetailSuccess(response.data));
      }
    })
    .catch(() => {});
};
export const userReport = (data) => async (dispatch) => {
  api
    .post(`${ADRENAAPI.userReport}`, data)
    .then((response) => {
      if (response.data.status) {
        toast.success('Add report successfully');
        dispatch(userReportSuccess({ data: response.data.date }));
        dispatch(userReportStatus());
      } else {
        toast.warning(response.data.message);
      }
    })
    .catch(() => {});
};
export const updateUserReportStatus = (setShowDialog) => async (dispatch) => {
  dispatch(userReportStatus());
  setShowDialog(false);
};
export default slice.reducer;
