import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Grid,
  InputLabel,
  Paper,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SellingNavbar from '../SellingNavbar';
import SellingFooter from '../sellingFooter';
import AdrenaInput from 'components/input';
import AdrenaLabel from 'components/label';
import { Icon } from '@iconify/react';
import ProductBrand from '../../../components/productBrand';
import AdBrandConfig from './adBrandConfig';
import { addproduct } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getproductDetail } from 'store';
import BrandDialog from 'components/dialogs/brandDialog';
import TagManager from 'react-gtm-module';
import { decrypt, encrypt } from 'constant';

var brandValue = [];
const tagManagerArgs = {
  dataLayer: {
    userProject: 'Adrena',
    page: 'Adrena-SellEquipmentBrand'
  }
};
export default function AdBrand() {
  TagManager.dataLayer(tagManagerArgs);
  const dispatch = useDispatch();
  var brand = [];
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [ShowDialog, setShowDialog] = useState(false);
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const { productDetails } = useSelector((state) => state.categorySlice);
  const [adFormData, setAdFormData] = useState();
  useEffect(() => {
    dispatch(getproductDetail(decrypt(id), i18n.language));
  }, []);
  useEffect(() => {
    if (productDetails) {
      setAdFormData(productDetails);
    }
  }, [productDetails]);
  useEffect(() => {
    if (brandValue?.length === 1) {
      handleAdd();
    }
  }, [brandValue]);
  const onBrandInput = (val, index, type) => {
    let temp = brandValue;
    let brandName = adFormData.extraInformation[index]?.brand;
    let brandSpecification = adFormData.extraInformation[index]?.specification;
    temp[index] = val;
    if (type === 'BRAND') {
      let data = {
        brand: val,
        specification: brandSpecification
      };
      brandValue[index] = data;
    } else {
      let data = {
        brand: brandName,
        specification: val
      };
      brandValue[index] = data;
    }
    setAdFormData((adFormData) => ({
      ...adFormData,
      extraInformation: brandValue
    }));
  };

  const handleSubmit = () => {
    let data = {
      productId: decrypt(id),
      language: i18n.language
    };
    const newObj = { ...adFormData, ...data };
    dispatch(addproduct(newObj, navigate, `/sell-equipment/ad-contacts/${encrypt(decrypt(id))}`));
  };
  const handleBack = () => {
    navigate(`/sell-equipment/ad-photos/${encrypt(decrypt(id))}`);
  };
  const handleAdd = () => {
    if (brandValue?.length === 0) {
      brandValue.push({ brand: adFormData?.extraInformation[0]?.brand, specification: '' });
    } else {
      brandValue.push({ brand: '', specification: '' });
    }
  };
  return (
    <>
      <BrandDialog
        ShowDialog={ShowDialog}
        setShowDialog={setShowDialog}
        heading={heading}
        subheading={subHeading}
      />
      <Container
        component="main"
        maxWidth="xxl"
        className="container"
        sx={{ position: 'relative' }}>
        <CssBaseline />
        <SellingNavbar handleBack={handleBack} />
        <Divider
          sx={{
            background: '#323232',
            mt: 3,
            display: { sm: 'none', xs: 'none', md: 'block', lg: 'block' }
          }}
        />
        <Container
          component="main"
          maxWidth="sm"
          sx={{
            textAlign: 'left',
            mt: 10,
            pb: 10
          }}>
          <Typography
            sx={{
              fontFamily: 'setimobold',
              fontSize: '32px',
              lineHeight: '36px',
              width: { xs: 330, sm: 330, md: 450, lg: 450 }
            }}>
            {t('selling.brandHeading')}
          </Typography>
          {brandValue.length > 0 ? (
            brandValue?.map((item, index) => {
              return (
                <Box key={index}>
                  <AdrenaInput
                    maxLength={30}
                    value={brandValue[index]?.brand}
                    // value={item?.brand}
                    label={t('selling.brand')}
                    labelStyle={{ marginTop: '3rem' }}
                    onChange={(val) => {
                      onBrandInput(val, index, 'BRAND');
                    }}
                  />
                  <InputLabel
                    sx={{
                      textAlign: 'right',
                      color: '#E7E9EA',
                      fontSize: '12px',
                      fontWeight: 400,
                      fontFamily: 'setimo'
                    }}>
                    {brandValue[index]?.brand?.length >= 1 ? brandValue[index]?.brand?.length : 0}
                    /30
                  </InputLabel>
                  <AdrenaInput
                    maxLength={50}
                    // value={item?.specification}
                    value={brandValue[index]?.specification}
                    label={t('selling.specification')}
                    labelIcon={
                      <Icon
                        icon="ic:outline-info"
                        color="#e7e9ea"
                        height="18px"
                        width="18px"
                        style={{ cursor: 'pointer', paddingTop: '0.01rem' }}
                        onClick={() => (
                          setHeading('SPECIFICATION'),
                          setShowDialog(!ShowDialog),
                          setSubHeading('Indicate which piece of equipment is the added brand.')
                        )}
                      />
                    }
                    minRows={2}
                    multiline={true}
                    onChange={(val) => {
                      onBrandInput(val, index, 'SPECIFICATION');
                    }}
                  />
                  <InputLabel
                    sx={{
                      textAlign: 'right',
                      color: '#E7E9EA',
                      fontSize: '12px',
                      fontWeight: 400,
                      fontFamily: 'setimo'
                    }}>
                    {brandValue[index]?.specification?.length >= 1
                      ? brandValue[index]?.specification?.length
                      : 0}
                    /50
                  </InputLabel>
                </Box>
              );
            })
          ) : (
            <>
              <AdrenaInput
                label={t('selling.brand')}
                value={adFormData?.extraInformation ? adFormData?.extraInformation[0]?.brand : ''}
                labelStyle={{ marginTop: '3rem' }}
                onChange={(val) => {
                  let temp = brand;
                  temp[0] = val;
                  let data = {
                    brand: val,
                    specification: null
                  };
                  brand[0] = data;

                  setAdFormData((adFormData) => ({
                    ...adFormData,
                    extraInformation: brand
                  }));
                }}
                maxLength={30}
              />
              <InputLabel
                sx={{
                  textAlign: 'right',
                  color: '#E7E9EA',
                  fontSize: '12px',
                  fontWeight: 400,
                  fontFamily: 'setimo'
                }}>
                {adFormData?.extraInformation?.length >= 1
                  ? adFormData?.extraInformation[0]?.brand?.length
                  : 0}
                /30
              </InputLabel>
            </>
          )}

          <Box sx={{ textAlign: 'left', display: 'inline-flex' }}>
            <AdrenaLabel
              style={{ marginTop: '-0.5rem', marginLeft: '-0.4rem' }}
              text={t('selling.brandBtn')}
              onClick={handleAdd}
              buttonStyle={{ textDecoration: 'underline' }}
            />
            <Icon
              onClick={() => (
                setHeading('ADD BRAND'),
                setShowDialog(!ShowDialog),
                setSubHeading(
                  'If your equipment is made up of elements from different brands, add a brand to specify all the elements you find necessary.'
                )
              )}
              style={{ cursor: 'pointer' }}
              icon="ic:outline-info"
              color="#e7e9ea"
              height="19px"
              width="19px"
            />
          </Box>
          <Divider
            sx={{
              backgroundColor: '#C8C9CB',
              display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }
            }}
          />
          <AdrenaInput
            label={t('selling.model')}
            value={adFormData?.model ? adFormData?.model : ''}
            labelStyle={{ marginTop: '1.5rem' }}
            style={{ marginBottom: '1.5rem' }}
            onChange={(val) => {
              setAdFormData((adFormData) => ({
                ...adFormData,
                model: val
              }));
            }}
          />
          <Divider
            sx={{
              backgroundColor: '#C8C9CB',
              position: 'absolute',
              left: 0,
              right: 0,
              display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' }
            }}
          />
          <Divider
            sx={{
              backgroundColor: '#C8C9CB',
              display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }
            }}
          />
          <AdrenaInput
            label={t('selling.modelYear').toUpperCase()}
            value={adFormData?.manufactureYear ? adFormData?.manufactureYear : ''}
            labelStyle={{ marginTop: '1.3rem' }}
            style={{ marginBottom: '1.5rem' }}
            onChange={(val) => {
              setAdFormData((adFormData) => ({
                ...adFormData,
                manufactureYear: val * 1
              }));
            }}
          />
          <Divider
            sx={{
              backgroundColor: '#C8C9CB',
              display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }
            }}
          />
          <Typography
            sx={{
              fontFamily: 'setimobold',
              fontSize: '16px',
              lineHeight: '22px',
              mt: 3
            }}>
            {t('selling.conservationState')}
          </Typography>
          <Grid
            container
            sx={{ mt: { xs: 0, sm: 0, md: 2, lg: 2 }, textAlign: 'left' }}
            spacing={1}>
            <ProductBrand
              data={AdBrandConfig}
              setAdFormData={setAdFormData}
              adFormData={adFormData}
            />
          </Grid>
        </Container>

        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'black',
            paddingBottom: '1rem'
          }}
          elevation={3}>
          <SellingFooter
            show="true"
            disabled={
              adFormData?.extraInformation
                ? adFormData?.extraInformation[0]?.brand && adFormData?.conservationId
                  ? false
                  : true
                : true
            }
            onClick={handleSubmit}
            Progress={67}
          />
        </Paper>
      </Container>
    </>
  );
}
