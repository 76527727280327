import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from 'assets/img/app-logo.png';
import { AppBar, Box, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { useDispatch } from 'react-redux';
import { clearProductDetails } from 'store';
import { clearImageListData } from 'store';

export default function SellingNavbar(props) {
  const { handleBack, show } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClose = () => {
    sessionStorage.clear();
    navigate('/');
    dispatch(clearImageListData());
    dispatch(clearProductDetails());
  };
  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: 'black' }}>
        <Box
          sx={{
            display: 'flex',

            justifyContent: 'space-between',
            pt: 3,
            px: { xs: 1.5, sm: 1.5, md: 10, lg: 10 }
          }}>
          {!show && (
            <Icon
              onClick={handleBack}
              icon="ooui:previous-ltr"
              color="#e7e9ea"
              width={25}
              height={25}
            />
          )}
          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }
            }}>
            <Link to="/">
              <img src={logo} style={{width:'9rem'}} alt="" />{' '}
            </Link>
          </Typography>
          <Icon
            onClick={handleClose}
            icon="icon-park-outline:close"
            color="#e7e9ea"
            width={25}
            height={25}
          />
        </Box>
      </AppBar>
    </>
  );
}
