const AdBrandConfig = [
  {
    id: 1,
    heading: 'new',
    subHeading: 'new',
    value: 'New',
    status: false
  },
  {
    id: 2,
    heading: 'greatCondition',
    subHeading: 'greatCondition',
    status: false,
    value: 'Great condition'
  },
  {
    id: 3,
    heading: 'goodState',
    subHeading: 'goodState',
    status: false,
    value: 'Good state'
  },
  {
    id: 4,
    heading: 'middleState',
    subHeading: 'middleState',
    status: false,
    value: 'Middle state'
  },
  {
    id: 5,
    heading: 'badState',
    subHeading: 'badState',
    status: false,
    value: 'Bad state'
  }
];

export default AdBrandConfig;
